import React, { Component } from "react";
import { Box, Typography, Button } from "@mui/material";

const style = {
    p: 2,
    color: "#953553",
    marginTop: 2,
    marginBottom: 2,
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#F2D2BD",
    borderRadius: 8,
    width: "90%",
}

class InfoPanel extends Component {
    render() {
        let announcement
        if (this.props.noWinner) {
            announcement = "No Winner :("
        } else if (this.props.winner) {
            announcement = "PLAYER " + this.props.winner + " WINS!"
        } else {
            announcement = "Turn: Player " + this.props.activePlayer
        }

        return <Box sx={style}>
            <Typography variant="body2" component="div" >
                {announcement}
            </Typography>
            <Button onClick={() => this.props.reset()} sx={{ color: "#953553" }}>Reset</Button>
        </Box>
    }
}

export default InfoPanel

