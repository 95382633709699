import React, { Component } from "react";
import { Button } from "@mui/material";

class Tile extends Component {
    render() {
        const tileIsClaimed = (this.props.label != null)

        return <Button
            variant="outline"
            disabled={tileIsClaimed || this.props.gameOver}
            onClick={() => this.props.handleClick(this.props.row, this.props.col)}
            sx={{minHeight: 40}}>
            {tileIsClaimed ? this.props.label : ""}
        </Button>;
    }
}

export default Tile

