import { Typography } from "@mui/material";
import React, { Component } from "react";


export default class DogPicComponent extends Component {

  constructor(props) {
    super(props)
    this.state = {
      error: null,
      isLoaded: false,
      result: null
    }
  }

  componentDidMount() {
    fetch("https://dog.ceo/api/breeds/image/random")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            result: result
          })
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          })
        }
      )
  }


  render() {
    const { error, isLoaded, result } = this.state;
    let image = null
    if (error) {
      image = <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      image = <div>Loading...</div>;
    } else {
      image = <img src={result.message} width={200} />
    }

    return (<>
      <Typography variant={"body1"}>Here is a random pic of a dog:</Typography>
      {image}
    </>)
  }
}