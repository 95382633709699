import React, { Component } from "react";
import Tile from './Tile'
import { Players } from "./TicTacToe";
import { Stack, Box, Divider } from "@mui/material";
import * as winUtils from "./util/winnerChecks"

class Board extends Component {

    claimTile(row, col) {
        let playerToClaim
        switch (this.props.activePlayer) {
            case Players.X:
                playerToClaim = 'X'
                break;
            case Players.O:
                playerToClaim = 'O'
                break;
            default:
                console.log('WAIT WHO ARE YOU?! (player unknown)')
        }

        let board = this.props.board
        let r0Copy = board.r0
        let r1Copy = board.r1
        let r2Copy = board.r2
        let updatedRow
        switch (row) {
            case 0:
                r0Copy[col] = playerToClaim;
                updatedRow = r0Copy
                break;
            case 1:
                r1Copy[col] = playerToClaim
                updatedRow = r1Copy
                break;
            case 2:
                r2Copy[col] = playerToClaim
                updatedRow = r2Copy
                break;

        }

        this.props.updateBoard({ r0: r0Copy, r1: r1Copy, r2: r2Copy })

        const isRowWinner = winUtils.checkRowForWin(updatedRow, playerToClaim)
        const isColWinner = winUtils.checkColForWin(col, board)
        const isDiagWinner = winUtils.isPositionDiagEligible(row, col) ? winUtils.checkDiagsForWin(board) : false
        if (isRowWinner || isColWinner || isDiagWinner) {
            this.props.setWinner()
        } else {
            const noWinner = this.noWinners()
            if (noWinner) {
                this.props.setNoWinner()
            }
        }

        this.props.switchPlayer()
    }

    noWinners() {
        const r0 = this.props.board.r0
        const r1 = this.props.board.r1
        const r2 = this.props.board.r2


        const rows = [r0, r1, r2]

        let full = true
        for (const row of rows) {
            for (const item of row) {
                if (item === null) {
                    full = false
                    break
                }
            }

        }

        return full
    }

    makeTile({ row, col, label }) {
        return <Tile key={"tile-" + row + "-" + col}
            row={row}
            col={col}
            label={label}
            gameOver={this.props.gameOver}
            handleClick={(row, col) => this.claimTile(row, col)} />
    }


    render() {
        const firstRowTiles = this.props.board.r0.map(
            (value, index) => this.makeTile({ row: 0, col: index, label: value })
        )
        const secondRowTiles = this.props.board.r1.map(
            (value, index) => this.makeTile({ row: 1, col: index, label: value })
        )
        const thirdRowTiles = this.props.board.r2.map(
            (value, index) => this.makeTile({ row: 2, col: index, label: value })
        )

        const vertDivider = <Divider orientation="vertical" flexItem color="#F2D2BD" />
        const horizontalDivider = <Divider orientation="horizontal" flexItem color="#F2D2BD" />

        return (
            <Box display="flex" flexDirection={"row"} justifyContent={"center"} marginTop={2} >
                <Stack alignItems="center" width="fit-content" divider={horizontalDivider}>
                    <Stack direction="row" divider={vertDivider}>{firstRowTiles}</Stack>
                    <Stack direction="row" divider={vertDivider}>{secondRowTiles}</Stack>
                    <Stack direction="row" divider={vertDivider}>{thirdRowTiles}</Stack>
                </Stack>
            </Box>

        )
    }
}


export default Board