
export const checkColForWin = function (col, board) {
    const center = board.r1[col]
    if (center == null) {
        return false
    }

    return board.r0[col] == center && center == board.r2[col]
}

export const isPositionDiagEligible = function (row, col) {
    const isMiddleTopLOrBottomR = row === col
    const isTopRCorner = row === 0 && col === 2
    const isBottomLCorner = row === 2 && col === 0
    return isMiddleTopLOrBottomR || isTopRCorner || isBottomLCorner
}

export const checkDiagsForWin = function (board) {
    const center = board.r1[1]
    if (center == null) {
        return false
    }
    const diagOne = board.r0[0] === center && board.r2[2] === center
    const diagTwo = board.r0[2] === center && board.r2[0] === center
    return diagOne || diagTwo
}

export const checkRowForWin = function (row, recentlyPlayedVal) {
    let isWinner = true
    row.map(
        (value) => {
            if (value != recentlyPlayedVal) {
                isWinner = false
            }
            return
        }
    )

    return isWinner
}