import React, { Component } from "react";
import Board from './Board';
import InfoPanel from './InfoPanel';
import { Container, Typography } from '@mui/material';

export const Players = {
  X: "X",
  O: "O"
}

export class TicTacToe extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
  }

  initialState() {
    return {
      winner: null,
      activePlayer: Players.X,
      board: {
        r0: [null, null, null],
        r1: [null, null, null],
        r2: [null, null, null]
      },
      noWinner: false,
    };
  }

  reset() {
    this.setState(this.initialState())
  }

  switchPlayer() {
    switch (this.state.activePlayer) {
      case Players.X:
        this.setState(() => ({ activePlayer: Players.O }))
        break;
      case Players.O:
        this.setState(() => ({ activePlayer: Players.X }))
        break;
      default:
        console.log("Error: invalid player")
    }
  }

  render() {
    return (
      <Container sx={{justifyContent: 'center'}}>
        <Typography>Would you like to play Tic Tac Toe?</Typography>
        <Board
          activePlayer={this.state.activePlayer}
          switchPlayer={() => this.switchPlayer()}
          setWinner={() => this.setState({ winner: this.state.activePlayer })}
          gameOver={this.state.winner !== null}
          updateBoard={(newBoard) => this.setState({ board: newBoard })}
          setNoWinner={() => this.setState({ noWinner: true })}
          board={this.state.board}
        />
        <InfoPanel
          activePlayer={this.state.activePlayer}
          noWinner={this.state.noWinner}
          reset={() => this.reset()} 
          winner={this.state.winner} />
      </Container>
    );
  }
}

