import { Box, Container, Typography } from '@mui/material';
import DogPicComponent from './components/DogPicComponent';
import { TicTacToe } from './tic-tac-toe/TicTacToe';
import { isMobile } from "react-device-detect";

function App() {
  const style = {
    width: "100%",
    height: "100vh",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#F2D2BD',
    button: {
      color: "#F2D2BD",
      backgroundColor: "#953553",
      "&:disabled": {
        color: "#F2D2BD",
      },
      "&:hover": {
        color: "#F2D2BD",
        backgroundColor: "#A95C68",
      }
    }
  }

  return (
    <Container sx={style}>
      <Typography variant="h2" fontWeight={200} marginTop={isMobile ? 2 : 8}>
        Hi, I'm Courtney. 👋
      </Typography>
      <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} marginTop={2}>
        <Box sx={{ margin: 2, borderRadius: 16, border: 1, p: 3, justifyContent: 'center'}}>
          <DogPicComponent />
        </Box>
        <Box sx={{ margin: 2, borderRadius: 16, border: 1, p: 3 }}>
          <TicTacToe />
        </Box>
      </Box>
    </Container>
  );
}

export default App;
